import React from "react"
import { useStaticQuery ,graphql, Link} from "gatsby"
import Img from 'gatsby-image'
import { FaBars } from 'react-icons/fa'
import './header.css'

export const Header = () =>{

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          fixed(width: 500, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    `
  );
  
  return(
    <header className="header">
      <div className="container">
        <div className="logo">
          <Img fixed={data.logo.childImageSharp.fixed}/>  
        </div>
        <div className="hamburger-menu" >
          <FaBars size="70px" color='#FFFFF'/>
          <ul className="hamburger-nav">
            <li className="colapse"><Link to={"/"}>Home</Link></li>  
            <li className="colapse"><Link to={"/product"}>Produtos</Link></li>  
            <li className="colapse"><Link to={"/contact"}>Contato</Link></li>  
          </ul>  
        </div>
        <nav >
          <ul className="nav__menu">
            <li><Link to={"/"}>Home</Link></li>
            <li><Link to={"/product"}>Produtos</Link></li>
            <li><Link to={"/contact"}>Contato</Link></li>
          </ul>
        </nav>
      </div> 
    </header>
  )
}

export default Header;
