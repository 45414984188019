import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa' 
import './footer.css'

const Footer = () =>{
  return (
      <footer className="footer">
        <div className="footer-content">
          <div className="all-rights">Todos direitos reservados Blendcerto</div>
        </div>
        <div className="social-midea">
          <a href="https://www.facebook.com/Blendcerto/?view_public_for=109366567203757"><FaFacebookF/></a>
          <a href="https://www.instagram.com/blendcerto/?hl=pt-br"><FaInstagram className="instagram"/></a>
        </div>
      </footer>
  )
} 

export default Footer;